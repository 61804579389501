import { useState } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { propOr } from 'ramda';
/* Styles */
import styles from 'styles/search-activity-card.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* API */
import { apiRequest } from 'shared/API';
import { useQueryClient } from '@tanstack/react-query';

/* Redux */
import { useDispatch } from 'react-redux';
import { setOpenOnEditMode } from 'app/slices/workSearchSlice';

/* Material UI and other UI Dependencies */
import AddIcon from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import DraftIcon from '@mui/icons-material/Edit';
import info from 'assets/images/Info_Cards.svg';
import { Tooltip, Box, CircularProgress } from '@mui/material';

import makeStyles from '@mui/styles/makeStyles';

// MUI v5
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

/* i18n */
import { useIntl } from 'react-intl';
import { trackEvent } from 'lib/analytics';

const useStylesBootstrap = makeStyles(() => ({
  arrow: {
    color: 'black',
  },
  tooltip: {
    backgroundColor: '#151515',
  },
}));

const deleteDraft = ({ worksearch_id }) => {
  return apiRequest('DELETE', '/users/me/worksearch/', { params: { worksearch_id } });
};

const SkillCard = ({
  item = { activity_type: 'add' },
  type,
  date = '',
  title = '',
  notClickable = false,
  onClick = () => {},
  saving = false,
}) => {
  const intl = useIntl();
  const tooltipClasses = useStylesBootstrap();
  const [openDialog, setOpenDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  if (item.activity_type === 'applied_for_job') {
    title = intl.formatMessage({ id: 'jobSearchActivity.activity.applied' }, { title: item.position_applied_for });
  }
  if (item.activity_type === 'attend_job_fair') {
    title = intl.formatMessage({ id: 'jobSearchActivity.activity.attendedFair' }, { title: item.job_fair });
  }
  if (item.activity_type === 'resume_upload') {
    title = intl.formatMessage({ id: 'jobSearchActivity.activity.uploadedResume' });
  }
  if (item.activity_type === 'participate_in_resea') {
    title = intl.formatMessage({ id: 'jobSearchActivity.activity.participatedReseaWorkshop' });
  }
  if (item.activity_type === 'interview_with_employer') {
    title = intl.formatMessage({ id: 'jobSearchActivity.activity.interviewed' });
  }

  return (
    <Box
      className={cn({
        [styles.card]: true,
        [styles.cardYellow]: type === 'ADD_NEW',
        [styles.cardDraft]: item.draft,
        [styles.notClickable]: notClickable || saving,
      })}
      onClick={saving ? null : onClick}
      sx={{
        opacity: saving ? 0.7 : 1,
      }}
    >
      <div>
        {item.draft ? (
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              setOpenDialog(true);
            }}
            aria-label="delete"
            size="small"
            sx={{ position: 'absolute', top: 18, right: 14 }}
          >
            <DeleteIcon fontSize="inherit" />
          </IconButton>
        ) : null}
        <div className={styles.iconSection}>
          <span
            className={cn({
              [styles.icon]: true,
              [styles.draftIcon]: item.draft,
              [styles.completeIcon]: type !== 'ADD_NEW' && !item.draft,
              [styles.addNewIcon]: type === 'ADD_NEW',
            })}
          >
            {item.draft && <DraftIcon className={styles.pencilIcon} />}
            {type !== 'ADD_NEW' && !item.draft && <CheckIcon className={styles.checkIcon} />}
            {type === 'ADD_NEW' && <AddIcon className={styles.addIcon} />}
          </span>
          <Box
            component="span"
            sx={{
              display: 'flex',
              alignItems: 'flex-start',
              pt: 0.2,
              pr: 1,
              ...(item.draft ? { color: (theme) => theme.palette.error.main } : {}),
            }}
          >
            {intl.formatMessage({
              id:
                type === 'ADD_NEW'
                  ? 'jobSearchActivity.card.addNew'
                  : item.draft
                  ? 'jobSearchActivity.draft'
                  : 'jobSearchActivity.card.complete',
            })}
            {item.draft && propOr(0, 'length', item.upload_documents) <= 0 ? (
              <Tooltip
                enterTouchDelay={0}
                title={
                  <Fragment>
                    <span style={{ fontSize: 14 }}>{intl.formatMessage({ id: 'jobSearchActivity.draftHelp' })}</span>
                  </Fragment>
                }
                placement="top"
                arrow
                classes={tooltipClasses}
              >
                <Box sx={{ color: (theme) => theme.palette.info.main }}>
                  <img
                    alt="Info"
                    src={info}
                    style={{
                      marginLeft: '4px',
                      width: '15px',
                      height: '15px',
                      cursor: 'pointer',
                    }}
                  />
                </Box>
              </Tooltip>
            ) : null}
          </Box>
        </div>
        <span className={styles.card__title}>{title}</span>
      </div>

      <div className={styles.dateSection}>
        {type !== 'ADD_NEW' ? (
          date
        ) : (
          <span
            className={cn(layoutStyles.link, styles.addActivityLink)}
            role="button"
            name={intl.formatMessage({ id: 'jobSearchActivity.card.addActivity' })}
            alt={intl.formatMessage({ id: 'jobSearchActivity.card.addActivity' })}
            id="add-activity-work-search-link"
          >
            {intl.formatMessage({ id: 'jobSearchActivity.card.addActivity' })}
          </span>
        )}
      </div>
      {type !== 'ADD_NEW' && !item.draft && propOr(0, 'length', item.upload_documents) <= 0 ? (
        <Tooltip
          enterTouchDelay={0}
          title={
            <Fragment>
              <span style={{ fontSize: 14 }}>
                {intl.formatMessage({ id: 'jobSearchActivity.supportingDocumentCTATooltip' })}
              </span>
            </Fragment>
          }
          placement="top"
          arrow
          classes={tooltipClasses}
        >
          <Box
            mt={1}
            onClick={() => {
              dispatch(setOpenOnEditMode(true));
              trackEvent('WEEK_ADD_SUPPORTING_DOC');
            }}
            sx={{
              color: (theme) => theme.palette.info.main,
              textDecoration: 'underline',
              fontWeight: 400,
              fontSize: 14,
            }}
          >
            {intl.formatMessage({ id: 'jobSearchActivity.supportingDocumentCTA' })}
            <img
              alt="Info"
              src={info}
              style={{
                position: 'relative',
                top: '3px',
                marginLeft: '4px',
                width: '15px',
                height: '15px',
                cursor: 'pointer',
              }}
            />
          </Box>
        </Tooltip>
      ) : null}
      <Dialog open={openDialog} onClose={() => setOpenDialog(false)} aria-labelledby="draggable-dialog-title">
        {loading ? (
          <Box pr={8} pl={8} pt={4} pb={4}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              {intl.formatMessage({ id: 'jobSearchActivity.deletePopup.title' }, { title: title })}
            </DialogTitle>
            <DialogContent>
              <DialogContentText>{intl.formatMessage({ id: 'jobSearchActivity.deletePopup.text' })}</DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                autoFocus
                onClick={(e) => {
                  e.stopPropagation();
                  setOpenDialog(false);
                }}
              >
                {intl.formatMessage({ id: 'jobSearchActivity.deletePopup.cancel' })}
              </Button>
              <Button
                onClick={async (e) => {
                  e.stopPropagation();
                  setLoading(true);
                  await deleteDraft(item);
                  queryClient.setQueryData(['/users/me/worksearch/'], (old) => {
                    return {
                      ...old,
                      ...{
                        activities: old.activities.filter((activity) => activity.worksearch_id !== item.worksearch_id),
                      },
                    };
                  });
                  setOpenDialog(false);
                }}
              >
                {intl.formatMessage({ id: 'jobSearchActivity.deletePopup.continue' })}
              </Button>
            </DialogActions>
          </>
        )}
      </Dialog>
    </Box>
  );
};

SkillCard.propTypes = {
  item: PropTypes.any,
  type: PropTypes.string,
  date: PropTypes.string,
  title: PropTypes.string,
  notClickable: PropTypes.bool,
  saving: PropTypes.bool,
  onClick: PropTypes.func,
};

export default SkillCard;
