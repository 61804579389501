import { useEffect, useRef } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

// 900000 ms = 15 minutes
const useAutoLogout = (timeout = 900000) => {
  const { logout, isAuthenticated } = useAuth0();
  const timeoutIdRef = useRef(null);

  const handleLogout = () => {
    if (isAuthenticated) {
      logout({ returnTo: `${window.location.origin}?logged_out=true`, federated: true });
    }
  };

  const resetTimer = () => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(handleLogout, timeout);
  };

  useEffect(() => {
    if (isAuthenticated) {
      // Event listeners for activity detection when authenticated
      const events = ['mousemove', 'mousedown', 'click', 'keypress', 'touchstart'];

      events.forEach((event) => window.addEventListener(event, resetTimer));

      resetTimer();

      // Cleanup event listeners and timeout on component unmount
      return () => {
        if (timeoutIdRef.current) {
          clearTimeout(timeoutIdRef.current);
        }
        events.forEach((event) => window.removeEventListener(event, resetTimer));
      };
    }
  }, [timeout, logout, isAuthenticated]);

  return null;
};

export default useAutoLogout;
