import { useState, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';

/* Styles */
import styles from 'styles/edit-job-activity.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* Analytics */
import { trackEvent } from 'lib/analytics';

/* Assets */
import Close from 'assets/images/Close';

/* Redux */
import { useSelector } from 'react-redux';

/* Material UI and other UI Dependencies */
import { Typography, Box, Select, InputLabel } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { grey } from '@mui/material/colors';
import MenuItem from '@mui/material/MenuItem';

/* Forms */
import ApplyJobForm from 'components/forms/ApplyJobForm';
import AttendJobFair from 'components/forms/AttendJobFair';
import InterviewWithEmployer from 'components/forms/InterviewWithEmployer';
import Resea from 'components/forms/Resea';

export default function EditJobActivity({
  editModalOpen,
  setEditModalOpen,
  addActivityItem,
  updateActivityItem,
  setGeneralActivity,
  selectedActivity = '',
  week,
}) {
  const [activity, setActivity] = useState('');
  const [activityToEdit, setActivityToEdit] = useState(selectedActivity);
  const [editting, setEditting] = useState(false);
  const user_settings = useSelector((state) => state.app.user_settings);

  const intl = useIntl();
  const validActivities = ['applied_for_job', 'interview_with_employer', 'attend_job_fair', 'participate_in_resea'];
  useEffect(() => {
    if (selectedActivity && validActivities.includes(selectedActivity.activity_type)) {
      setActivity(selectedActivity.activity_type);
      setActivityToEdit(selectedActivity);
      setEditting(true);
    } else {
      setActivity('');
      setActivityToEdit(false);
      setEditting(false);
    }
  }, [selectedActivity]);

  const editActivityOptions = {
    ...(user_settings.job_search_activity_form_option_apply && {
      applied_for_job: intl.formatMessage({ id: 'jobSearchActivity.applyForAJob' }),
    }),
    ...(user_settings.job_search_activity_form_option_interview && {
      interview_with_employer: intl.formatMessage({ id: 'jobSearchActivity.interviewWithEmployer' }),
    }),
    ...(user_settings.job_search_activity_form_option_attend_seminar && {
      attend_job_fair: intl.formatMessage({ id: 'jobSearchActivity.attendJobFair' }),
    }),
    ...(user_settings.job_search_activity_form_option_register_school_facility && {
      participate_in_resea: intl.formatMessage({ id: 'jobSearchActivity.participateInWorkshop' }),
    }),
  };
  const editActivityOptionsIDs = {
    applied_for_job: 'apply-for-job-work-search-dropdown',
    interview_with_employer: 'complete-an-interview-work-search-dropdown',
    attend_job_fair: 'job-fair-work-search-dropdown',
    participate_in_resea: 'resea-workshop-work-search-dropdown',
  };

  const formsDict = {
    applied_for_job: ApplyJobForm,
    interview_with_employer: InterviewWithEmployer,
    attend_job_fair: AttendJobFair,
    participate_in_resea: Resea,
  };
  const ActiveForm = activity ? formsDict[activity] : null;

  const handleActivityChange = ({ target: { value } }) => {
    setEditting(false);
    setActivityToEdit(false);
    setActivity(value);
  };

  return (
    <>
      <div
        className={cn({
          [styles.editJobContact]: true,
          [styles.hidden]: !editModalOpen,
          [styles.opaque]: editModalOpen,
        })}
      >
        <Close className={styles.close} onClick={() => setEditModalOpen(false)} />
        <Box component="section" sx={{ display: 'flex', flexDirection: 'column', padding: '26px 30px 32px' }}>
          <Typography sx={{ fontWeight: 700, pb: 2, color: grey['900'] }} component="span">
            {intl.formatMessage({
              id: editting ? 'jobSearchActivity.editJobContactActivity' : 'jobSearchActivity.createJobContactActivity',
            })}
          </Typography>
          <FormControl
            variant="filled"
            sx={{
              width: { xs: '100% !important', md: '408px !important' },
            }}
          >
            <InputLabel id="select-activity-type-label" htmlFor="activity-type-work-search-select">
              {intl.formatMessage({ id: 'jobSearchActivity.chooseActivity' })}
            </InputLabel>
            <Select
              id="activity-type-work-search-select"
              labelId="select-activity-type-label"
              name="activity"
              displayEmpty
              disabled={editting}
              value={activity}
              onChange={handleActivityChange}
              onOpen={() => {
                trackEvent('WEEK_ADDACTIVITY_DROPDOWN');
              }}
              MenuProps={{
                sx: {
                  maxHeight: 440,
                  maxWidth: 200,
                },
              }}
              sx={{
                '.MuiFilledInput-input': {
                  maxHeight: 43,
                  boxSizing: 'border-box',
                  padding: '15px 11px 13px!important',
                },
                '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
              }}
              variant="filled"
              inputProps={{
                title: intl.formatMessage({ id: 'jobSearchActivity.chooseActivity' }),
              }}
            >
              {Object.keys(editActivityOptions).map((option) => (
                <MenuItem
                  key={option}
                  value={option}
                  sx={{ fontSize: 13, whiteSpace: 'break-spaces', lineHeight: '18px', color: grey['800'] }}
                  id={editActivityOptionsIDs[option]}
                >
                  {editActivityOptions[option]}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {editModalOpen && activity !== '' ? <hr className={layoutStyles.separator} /> : null}
      </div>
      {editModalOpen && activity !== '' ? (
        <section className={styles.formSection}>
          {activity ? (
            <ActiveForm
              addActivityItem={addActivityItem}
              updateActivityItem={updateActivityItem}
              setGeneralActivity={setGeneralActivity}
              setOpen={setEditModalOpen}
              selectedActivity={activityToEdit}
              editting={editting}
              week={week}
            />
          ) : null}
        </section>
      ) : null}
      {editModalOpen && activity === '' ? <Box style={{ paddingBottom: 160 }} /> : null}
    </>
  );
}

EditJobActivity.propTypes = {
  editModalOpen: PropTypes.bool,
  setEditModalOpen: PropTypes.func,
  addActivityItem: PropTypes.func,
  updateActivityItem: PropTypes.func,
  setGeneralActivity: PropTypes.func,
  selectedActivity: PropTypes.any,
  week: PropTypes.object,
};
