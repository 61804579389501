import { useState } from 'react';
import PropTypes from 'prop-types';
import { Card, CardHeader, Chip, IconButton, Button, CardContent, Typography, Box, darken } from '@mui/material';
import { Menu, MenuItem } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Grid from '@mui/material/Unstable_Grid2';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbUpAltIcon from '@mui/icons-material/ThumbUpAlt';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import PlaceOutlinedIcon from '@mui/icons-material/PlaceOutlined';
import ReplyOutlinedIcon from '@mui/icons-material/ReplyOutlined';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';

/* i18n */
import { useIntl } from 'react-intl';
import { useTheme } from '@emotion/react';

function newTab(url) {
  window.open(url, '_blank');
}

export default function JobsCard({
  item,
  onButtonClick,
  rating,
  onLikeClick,
  onDislikeClick,
  ratingsPage = false,
  homePage = false,
}) {
  const intl = useIntl();
  const { palette } = useTheme();
  const LikeIcon =
    rating === 'liked' ? ThumbUpAltIcon : rating === 'disliked' && ratingsPage ? ReplyOutlinedIcon : ThumbUpOffAltIcon;

  // Hide recommendation menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleApplyClick = async (e) => {
    if (item.apply) {
      newTab(item.apply);
    }
    onButtonClick(e);
  };
  const salaryText = getSalaryRange(item);

  return (
    <Grid xs={12} md={4}>
      <Card
        sx={{
          height: '100%',
          boxShadow: ratingsPage ? 'none' : '0px 3px 12px rgba(0,0,0,0.1)',
          border: ratingsPage ? 'solid 1px rgba(0, 0, 0, 0.12)' : 'none',
        }}
      >
        <CardHeader
          action={
            homePage ? null : (
              <>
                <Button
                  startIcon={<LikeIcon sx={{ color: 'primary.dark' }} />}
                  onClick={onLikeClick}
                  sx={{ color: 'primary.dark', px: ratingsPage ? 2 : 1 }}
                  id="save-jobs-button"
                >
                  {intl.formatMessage({
                    id:
                      rating === 'liked'
                        ? ratingsPage
                          ? 'ratings.unsave'
                          : 'careers.saved'
                        : rating === 'disliked' && ratingsPage
                        ? 'ratings.unhide'
                        : 'careers.saveThis',
                  })}
                </Button>
                {!ratingsPage && (
                  <IconButton aria-label="settings" onClick={handleClick} sx={{ color: '#202124' }}>
                    <MoreVertIcon />
                  </IconButton>
                )}

                <Menu
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                  sx={{
                    '& .MuiPaper-root': {
                      boxShadow: '1px 3px 5px rgba(0, 0, 0, 0.2)',
                    },
                  }}
                  disableScrollLock
                >
                  <MenuItem
                    onClick={() => {
                      handleClose();
                      onDislikeClick();
                    }}
                    id="hide-recommendation-jobs-link"
                  >
                    {intl.formatMessage({ id: 'recommendations.hide' })}
                  </MenuItem>
                </Menu>
              </>
            )
          }
          sx={{ px: 2.5 }}
        />
        <CardContent sx={{ pt: 1.5, height: 'calc(100% - 64px)', boxSizing: 'border-box' }}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              px: { md: 1 },
              justifyContent: 'space-between',
              height: '100%',
            }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
              <Box sx={{ minHeight: 43, pb: 1 }}>
                <Typography
                  variant="h4"
                  component="h2"
                  sx={{ lineHeight: '24px', color: darken(palette.secondary.main, 0.3) }}
                >
                  {`title_${intl.locale}` in item && item[`title_${intl.locale}`].trim() !== ''
                    ? item[`title_${intl.locale}`]
                    : item.title}
                </Typography>
                <Box sx={{ color: darken(palette.secondary.main, 0.3) }}>{item.org}</Box>
              </Box>
              <Chip
                label={`${intl.formatMessage({ id: 'careers.filter.careers' })}: ${
                  `soc_name_${intl.locale}` in item && item[`soc_name_${intl.locale}`]
                    ? item[`soc_name_${intl.locale}`]
                    : item.soc_name
                }`}
                variant="outlined"
                title={item.soc_name}
                sx={{
                  mt: 0.6,
                  borderRadius: 2,
                  borderColor: 'secondary.main',
                  '& .MuiChip-label': { color: darken(palette.secondary.dark, 0.3) },
                }}
              />

              <Box sx={{ mt: 2, minHeight: 49 }}>
                {salaryText ? (
                  <Box>
                    <PaidOutlinedIcon sx={{ fontSize: 20, position: 'relative', top: 4 }} /> {salaryText}
                  </Box>
                ) : null}
                <Box>
                  <PlaceOutlinedIcon sx={{ fontSize: 20, position: 'relative', top: 4 }} /> {item.city}
                </Box>
              </Box>
            </Box>
            <Button
              color="primary"
              type="button"
              variant="contained"
              onClick={handleApplyClick}
              sx={{
                pl: 2,
                pr: 2,
                mt: { xs: 1.5 },
              }}
              aria-label={intl.formatMessage({ id: 'jobs.learnAndApply' })}
              id="visit-job-posting-jobs-button"
            >
              {intl.formatMessage({
                id: 'jobs.learnAndApply',
              })}
              &nbsp;
              <span>
                <OpenInNewIcon sx={{ fontSize: 16, position: 'relative', top: 3, marginLeft: 0.5 }} />
              </span>
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
}

function getSalaryRange(item) {
  const intl = useIntl();
  if (!item.salary_median) {
    return '';
  }
  return `$${Math.floor(item.salary_median / 1000)}K/${intl.formatMessage({
    id: 'training.duration.year',
  })}`;
}

JobsCard.propTypes = {
  item: PropTypes.shape({
    guid: PropTypes.string,
    job_id: PropTypes.string,
    title: PropTypes.string,
    city: PropTypes.string,
    org: PropTypes.string,
    soc: PropTypes.string,
    soc_name: PropTypes.string,
    average_pay_state: PropTypes.string,
    salary_median: PropTypes.string,
    common_skills: PropTypes.string,
    apply: PropTypes.string,
  }),
  onButtonClick: PropTypes.func,
  onLikeClick: PropTypes.func,
  onDislikeClick: PropTypes.func,
  rating: PropTypes.string,
  ratingsPage: PropTypes.bool,
  homePage: PropTypes.bool,
};
