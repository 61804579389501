import Footer from './Footer';
import Header from './Header';
import PropTypes from 'prop-types';

/* Styles */
import styles from 'styles/layout.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* Browser */
import { isIE } from 'react-device-detect';

const Layout = ({ children, locale, loggedIn, signOut, serverUserLocale }) => {
  const intl = useIntl();
  const productName = process.env.REACT_APP_PRODUCT_NAME;
  const locality = process.env.REACT_APP_LOCALITY;
  const environment = process.env.REACT_APP_ENVIRONMENT;

  return (
    <div className={styles.layout}>
      <div>
        {isIE && <div className={styles.browserBanner}>{intl.formatMessage({ id: 'browsers.ie.banner' })}</div>}
        {environment !== 'prod' && (
          <div className={styles.envBanner}>
            {intl.formatMessage({ id: 'app.envBanner' }, { productName, environment: environment, locality: locality })}
          </div>
        )}
        <Header locale={locale} serverUserLocale={serverUserLocale} loggedIn={loggedIn} signOut={signOut} />
        <main>{children}</main>
      </div>
      <Footer />
    </div>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  locale: PropTypes.string,
  serverUserLocale: PropTypes.string,
  loggedIn: PropTypes.bool,
  signOut: PropTypes.func,
};

export default Layout;
