import { useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import PropTypes from 'prop-types';

/* Styles */
import { css } from '@emotion/css';
import cssVars from 'styles/vars.module.scss';

/* Assets */
import { PoweredByCOS, ONETCodeConnector, BLS, NLxResearchHub, SocKit } from '../../../images/logos';

/* Analytics */
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import { Typography, Container, Grid } from '@mui/material';
import CloseTabButton from '../../CloseTabButton';

const makeStyles = ({ locality }) => ({
  content: css(
    {
      padding: '30px 0',
      ...(locality === 'nj' && {
        backgroundColor: cssVars.white,
      }),
    },
    { label: 'content' }
  ),
  creditsContainer: css(
    {
      margin: '20px',
      padding: '20px',
      marginBottom: '40px',
      background: cssVars.white,
      borderRadius: '10px',
      ...(locality === 'nj' && {
        boxShadow: '0px 2px 10px 0px rgb(0 0 0 / 13%)',
      }),
    },
    { label: 'credits-container' }
  ),
  creditsLogo: css(
    {
      padding: '20px',
      textAlign: 'center',
    },
    { label: 'credits-logo' }
  ),
  creditsLogoNLx: css(
    {
      backgroundColor: '#1c2f42',
      padding: '20px',
      ...(locality === 'co' && {
        backgroundColor: '#001970',
      }),
    },
    { label: 'credits-logo--nlx' }
  ),
  link: css(
    {
      color: `${cssVars.actionBlue} !important`,
      fontWeight: '700',
      textCecoration: 'underline',
      wordBreak: 'break-word',
      '&:visited': {
        color: `${cssVars.actionBlue} !important`,
        fontWeight: '700',
        textCecoration: 'underline',
      },
      '&:hover, &:active': {
        color: cssVars.actionBlueHover,
        cursor: 'pointer',
      },
    },
    { label: 'link' }
  ),
  creditsCitation: css(
    {
      marginTop: '12px',
      borderLeft: `${cssVars.mediumGray} 3px solid`,
      paddingLeft: '6px',
      fontSize: '85%',
      lineHeight: '1.4',
    },
    { label: 'credits-citation' }
  ),
  innerShadow: css(
    {
      boxShadow: 'inset 0px 4px 4px 0px rgb(0 0 0 / 12%)',
      height: '9px',
    },
    { label: 'inner-shadow' }
  ),
  titleContainer: css({
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '@media screen and (max-width: 671px)': {
      flexDirection: 'column',
    },
    '@media screen and (min-width: 672px)': {
      paddingLeft: 20,
    },
  }),
});

const CreditsCitations = () => {
  const locality = process.env.REACT_APP_LOCALITY;
  const intl = useIntl();
  const styles = makeStyles({ cssVars, locality });

  useEffect(() => {
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('TERMSOFUSE');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  return (
    <>
      {locality === 'nj' && <div className={styles.innerShadow} />}
      <div className={styles.content}>
        <Container>
          <div className={styles.titleContainer}>
            <Typography variant="h1" gutterBottom>
              {intl.formatMessage({ id: 'layout.footer.credits' })}
            </Typography>
            <CloseTabButton inlineStyle={{ float: 'initial', margin: '20px' }} />
          </div>
          <Grid container>
            <Grid item md={6}>
              <div className={styles.creditsContainer}>
                <div>
                  <a target="_blank" rel="noreferrer" href="https://www.careeronestop.org/">
                    <img src={PoweredByCOS} alt="Powered by CareerOneStop" />
                  </a>
                </div>
                <Typography variant="body2" component="p" gutterBottom>
                  <FormattedMessage
                    id="credits.cos"
                    values={{
                      cosLink: (chunks) => <ExternalLink url="https://www.careeronestop.org/">{chunks}</ExternalLink>,
                      regLink: (chunks) => (
                        <ExternalLink url="https://www.careeronestop.org/JobSearch/job-search.aspx">
                          {chunks}
                        </ExternalLink>
                      ),
                    }}
                  />
                </Typography>
              </div>
              <div className={styles.creditsContainer}>
                <div className={styles.creditsLogo}>
                  <a target="_blank" rel="noreferrer" href="https://www.careeronestop.org/">
                    <img src={ONETCodeConnector} alt="Powered by O*NET Code Connector" width="180" />
                  </a>
                </div>
                <Typography variant="body2" component="p" gutterBottom>
                  <FormattedMessage
                    id="credits.onet"
                    values={{
                      onetLink: (chunks) => (
                        <ExternalLink url="https://www.onetcodeconnector.org/">{chunks}</ExternalLink>
                      ),

                      ccLink: (chunks) => (
                        <ExternalLink url="https://creativecommons.org/licenses/by/4.0/">{chunks}</ExternalLink>
                      ),
                    }}
                  />
                </Typography>
              </div>
              <div className={styles.creditsContainer}>
                <div className={styles.creditsLogo}>
                  <a target="_blank" rel="noreferrer" href="https://research.ripl.org/#/sockit">
                    <img src={SocKit} alt="Powered by O*NET Code Connector" width="180" />
                  </a>
                </div>
                <Typography variant="body2" component="p" gutterBottom>
                  <FormattedMessage
                    id="credits.sockit"
                    values={{
                      link: (chunks) => <ExternalLink url="https://research.ripl.org/#/sockit">{chunks}</ExternalLink>,
                    }}
                  />
                </Typography>
              </div>
            </Grid>
            <Grid item md={6}>
              <div className={styles.creditsContainer}>
                <div className={styles.creditsLogo}>
                  <a target="_blank" rel="noreferrer" href="https://www.careeronestop.org/">
                    <img src={BLS} alt="Bureau of Labor Statistics" width="160" />
                  </a>
                </div>
                <Typography variant="body2" component="p" gutterBottom>
                  <FormattedMessage
                    id="credits.bls"
                    values={{
                      link: (chunks) => (
                        <ExternalLink url="https://www.bls.gov/oes/current/oes_research_estimates.htm">
                          {chunks}
                        </ExternalLink>
                      ),
                    }}
                  />
                </Typography>
              </div>
              <div className={styles.creditsContainer}>
                <div className={styles.creditsLogo}>
                  <a target="_blank" rel="noreferrer" href="https://nlxresearchhub.org/">
                    <img src={NLxResearchHub} alt="NLx Research Hub" width="160" className={styles.creditsLogoNLx} />
                  </a>
                </div>
                <Typography variant="body2" component="p" gutterBottom>
                  <FormattedMessage
                    id="credits.nlx"
                    values={{
                      link: (chunks) => <ExternalLink url="https://nlxresearchhub.org/">{chunks}</ExternalLink>,
                    }}
                  />
                </Typography>
              </div>
              <div className={styles.creditsContainer}>
                <Typography variant="body2" component="p" gutterBottom>
                  <FormattedMessage
                    id="credits.soccer"
                    values={{
                      soccerLink: (chunks) => <ExternalLink url="https://soccer.nci.nih.gov/">{chunks}</ExternalLink>,
                      nciLink: (chunks) => <ExternalLink url="https://www.cancer.gov/">{chunks}</ExternalLink>,
                    }}
                  />
                </Typography>
                <Typography variant="body" component="p" gutterBottom className={styles.creditsCitation}>
                  Russ DE, Ho K-Y, Colt JS, <i>et al</i>. 2016. Computer-based coding of free-text job descriptions to
                  efficiently identify occupations in epidemiological studies. <i>Occup. Environ. Med.</i> <b>73</b>(6):
                  417-424. doi:
                  <a
                    target="_blank"
                    className={styles.link}
                    rel="noreferrer"
                    href="https://doi.org/10.1136/oemed-2015-103152"
                  >
                    10.1136/oemed-2015-103152
                  </a>
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  );
};

function ExternalLink({ url, children }) {
  return (
    <Typography
      target="_blank"
      rel="noreferrer"
      href={url}
      component="a"
      sx={{
        wordBreak: 'break-word',
        fontWeight: 700,
        color: (theme) => theme.palette.info.main,
      }}
    >
      {children}
    </Typography>
  );
}
ExternalLink.propTypes = {
  url: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
};

export default CreditsCitations;
