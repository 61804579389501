const CONTINUED_CLAIM_QUESTIONS = {
  q01_01: {
    label: {
      en: 'During the week did you work for an employer?',
      es: '¿Durante la semana trabajaste para una empleadora?',
    },
  },
  q01_02: {
    label: {
      en: 'Enter your last day worked during the week.',
      es: 'Ingrese su último día trabajado durante la semana.',
    },
    helperText: {
      en: 'MM/DD/YYYY',
      es: 'MM/DD/YYYY',
    },
  },
  q01_03: {
    label: {
      en: 'Select one option from the list which describes your current work status:',
      es: 'Seleccione una opción de la lista que describa su estado laboral actual:',
    },
    options: [
      {
        value: 'Still Employed or Working Reduced Hours',
        label: {
          en: 'Still Employed or Working Reduced Hours',
          es: 'Todavía empleado o trabajando con horas reducidas',
        },
      },
      {
        value: 'Laid Off',
        label: {
          en: 'Laid Off',
          es: 'Cesado',
        },
      },
      {
        value: 'Fired',
        label: {
          en: 'Fired',
          es: 'Despedido',
        },
      },
      {
        value: 'Quit',
        label: {
          en: 'Quit',
          es: 'Renuncio',
        },
      },
      {
        value: 'Other',
        label: {
          en: 'Other',
          es: 'Otro',
        },
      },
    ],
  },
  q01_04: {
    label: {
      en: 'Have you given a statement to the UI Hotline concerning this separation?',
      es: '¿Ha dado una declaración a la línea directa de UI sobre esta separación?',
    },
  },
  q01_05: {
    label: {
      en: 'Enter the total hours worked, not including minutes.',
      es: 'Ingrese el total de horas trabajadas, sin incluir los minutos.',
    },
    helperText: {
      en: 'Do not include minutes, e.g., for 1.36 hours, enter 1 hour.',
      es: 'No incluya minutos, por ejemplo, para 1,36 horas, ingrese 1 hora.',
    },
  },
  q01_06: {
    label: {
      en: 'Enter the gross earnings before deductions for the week whether you have been paid or not. Enter the amount in dollars and cents',
      es: 'Ingrese los ingresos brutos antes de las deducciones de la semana, ya sea que le hayan pagado o no. Ingrese el monto en dólares y centavos',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  // Question 2
  q02_07: {
    label: {
      en: 'Self-Employment',
      es: 'Trabajo por cuenta propia',
    },
  },
  q02_08: {
    label: {
      en: 'Enter the total hours worked, not including minutes.',
      es: 'Ingrese el total de horas trabajadas, sin incluir los minutos.',
    },
    helperText: {
      en: 'Do not include minutes, e.g., for 1.36 hours, enter 1 hour.',
      es: 'No incluya minutos, por ejemplo, para 1,36 horas, ingrese 1 hora.',
    },
  },
  q02_09: {
    label: {
      en: 'Enter the gross earnings before deductions for the week whether you have been paid or not. Enter the amount in dollars and cents',
      es: 'Ingrese los ingresos brutos antes de las deducciones de la semana, ya sea que le hayan pagado o no. Ingrese el monto en dólares y centavos',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  q03_10: {
    label: {
      en: 'Military Drill',
      es: 'Simulacro militar',
    },
  },
  q03_11: {
    label: {
      en: 'Enter the total hours you were at drill, not including minutes.',
      es: 'Ingrese el total de horas que estuvo en el simulacro, sin incluir los minutos.',
    },
    helperText: {
      en: 'Do not include minutes, e.g., for 1.36 hours, enter 1 hour.',
      es: 'No incluya minutos, por ejemplo, para 1,36 horas, ingrese 1 hora.',
    },
  },
  q03_12: {
    label: {
      en: 'Enter the gross earnings before deductions for the week whether you have been paid or not. Enter the amount in dollars and cents',
      es: 'Ingrese los ingresos brutos antes de las deducciones de la semana, ya sea que le hayan pagado o no. Ingrese el monto en dólares y centavos',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  q04_13: {
    label: {
      en: 'Holiday Pay',
      es: 'Vacaciones Pagas',
    },
  },
  q04_14: {
    label: {
      en: 'Enter the total hours of holiday, not including minutes.',
      es: 'Ingrese el total de horas de vacaciones, sin incluir los minutos.',
    },
    helperText: {
      en: 'Do not include minutes, e.g., for 1.36 hours, enter 1 hour.',
      es: 'No incluya minutos, por ejemplo, para 1,36 horas, ingrese 1 hora.',
    },
  },
  q04_15: {
    label: {
      en: 'Enter the gross earnings before deductions for the week whether you have been paid or not. Enter the amount in dollars and cents',
      es: 'Ingrese los ingresos brutos antes de las deducciones de la semana, ya sea que le hayan pagado o no. Ingrese el monto en dólares y centavos',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  q05_16: {
    label: {
      en: 'Vacation Pay',
      es: 'Vacaciones Pagas',
    },
  },
  q05_17: {
    label: {
      en: 'Enter the total hours of vacation, not including minutes.',
      es: 'Ingrese el total de horas de vacaciones, sin incluir los minutos.',
    },
    helperText: {
      en: 'Do not include minutes, e.g., for 1.36 hours, enter 1 hour.',
      es: 'No incluya minutos, por ejemplo, para 1,36 horas, ingrese 1 hora.',
    },
  },
  q05_18: {
    label: {
      en: 'Enter the gross earnings before deductions for the week whether you have been paid or not. Enter the amount in dollars and cents',
      es: 'Ingrese los ingresos brutos antes de las deducciones de la semana, ya sea que le hayan pagado o no. Ingrese el monto en dólares y centavos',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  q06_19: {
    label: {
      en: 'PTO',
      es: 'Tiempo remunerado',
    },
  },
  q06_20: {
    label: {
      en: 'Enter the total hours of PTO time, not including minutes.',
      es: 'Ingrese el total de horas de tiempo remunerado, sin incluir los minutos.',
    },
    helperText: {
      en: 'Do not include minutes, e.g., for 1.36 hours, enter 1 hour.',
      es: 'No incluya minutos, por ejemplo, para 1,36 horas, ingrese 1 hora.',
    },
  },
  q06_21: {
    label: {
      en: 'Enter the gross earnings before deductions for the week whether you have been paid or not. Enter the amount in dollars and cents',
      es: 'Ingrese los ingresos brutos antes de las deducciones de la semana, ya sea que le hayan pagado o no. Ingrese el monto en dólares y centavos',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  q07_22: {
    label: {
      en: 'Bonus Pay',
      es: 'Pago extra',
    },
  },
  q07_23: {
    label: {
      en: 'Enter the gross bonus pay before deductions. Enter the amount in dollars and cents',
      es: 'Ingrese el pago de bonificación bruto antes de las deducciones. Ingrese el monto en dólares y centavos',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  // Question 3
  q08_24: {
    label: {
      en: 'During the week were you off on sick leave that you will be paid for or will be paid?',
      es: '¿Durante la semana estuvo de baja por enfermedad por la que le pagarán o le pagarán?',
    },
  },
  q08_25: {
    label: {
      en: 'Enter the total hours of sick leave, not including minutes.',
      es: 'Ingrese el total de horas de licencia por enfermedad, sin incluir los minutos.',
    },
    helperText: {
      en: 'Do not include minutes, e.g., for 1.36 hours, enter 1 hour.',
      es: 'No incluya minutos, por ejemplo, para 1,36 horas, ingrese 1 hora.',
    },
  },
  q08_26: {
    label: {
      en: 'Enter the gross earnings before deductions for the week whether you have been paid or not. Enter the amount in dollars and cents',
      es: 'Ingrese los ingresos brutos antes de las deducciones de la semana, ya sea que le hayan pagado o no. Ingrese el monto en dólares y centavos',
    },
    helperText: {
      en: 'For e.g., enter one hundred twenty-five dollars and seventy five cents as 125.75',
      es: 'Por ejemplo, ingrese ciento veinticinco dólares con setenta y cinco centavos como 125.75',
    },
  },
  // Question 4
  q09_27: {
    label: {
      en: 'Have you notified the UI Hotline concerning your self-employment, military drill, holiday pay, vacation pay, sick pay, PTO, or bonus pay?',
      es: '¿Ha notificado a la línea directa de UI sobre su trabajo por cuenta propia, ejercicios militares, pago de días festivos, pago de vacaciones, pago por enfermedad, PTO o pago de bonificaciones?',
    },
  },
  // Question 5
  q10_28: {
    label: {
      en: 'Were you able and available for work each day of the week?',
      es: '¿Estuvo capaz y disponible para trabajar todos los días de la semana?',
    },
  },
  q10_29: {
    label: {
      en: 'Did you give a statement to the UI Hotline concerning this issue?',
      es: '¿Dio una declaración a la línea directa de UI sobre este tema?',
    },
  },
  // Question 6
  q11_30: {
    label: {
      en: 'During the week did you refuse any work, a referral to work, a recall to a former employer or failed to appear for a pre-employment drug screen?',
      es: 'Durante la semana, ¿rechazó algún trabajo, una recomendación para trabajar, un llamado a un antiguo empleador o no se presentó a una prueba de detección de drogas previa al empleo?',
    },
  },
  q11_31: {
    label: {
      en: 'Did you give a statement to the UI Hotline concerning this issue?',
      es: '¿Dio una declaración a la línea directa de UI sobre este tema?',
    },
  },
  // Question 7
  q12_33: {
    label: {
      en: 'Did you give a statement to the UI Hotline concerning this issue?',
      es: '¿Dio una declaración a la línea directa de UI sobre este tema?',
    },
  },
  // Question 8
  q13_34: {
    label: {
      en: 'During this week did you start to school or begin a training program?',
      es: '¿Durante esta semana empezaste a ir a la escuela o comenzaste un programa de capacitación?',
    },
  },
  q13_35: {
    label: {
      en: 'Did you give a statement to the UI Hotline concerning this issue?',
      es: '¿Dio una declaración a la línea directa de UI sobre este tema?',
    },
  },
  // Question 9
  q14_36: {
    label: {
      en: 'During this week did you apply for or begin receiving any retirement pay or separation pay, not including Social Security?',
      es: '¿Durante esta semana solicitó o comenzó a recibir algún pago de jubilación o pago de separación, sin incluir el Seguro Social?',
    },
  },
  q14_37: {
    label: {
      en: 'Did you give a statement to the UI Hotline concerning this issue?',
      es: '¿Dio una declaración a la línea directa de UI sobre este tema?',
    },
  },
  // Question 10
  q15_38: {
    label: {
      en: 'During this week, did you make your five job contacts?',
      es: '¿Durante esta semana hiciste tus cinco contactos laborales?',
    },
  },
  q15_39: {
    label: {
      en: 'Did you give a statement to the UI Hotline concerning this issue?',
      es: '¿Dio una declaración a la línea directa de UI sobre este tema?',
    },
  },
  // Question 11
  q16_40: {
    label: {
      en: 'Did you start working full time after this week?',
      es: '¿Empezaste a trabajar a tiempo completo después de esta semana?',
    },
  },
  q16_41: {
    label: {
      en: 'Enter the name of the employer',
      es: 'Ingrese el nombre del empleador',
    },
  },
};

export default CONTINUED_CLAIM_QUESTIONS;
