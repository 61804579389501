import axios from 'axios';

export class ApiError extends Error {
  /**
   * @param {import("axios").AxiosResponse} res
   * @param {string} message
   */
  constructor(res, message) {
    super(message);

    const { status } = res;

    this.status = status;
  }
}

// Axios wrapper functions:
// See https://axios-http.com/docs/req_config
export const apiRequest = async (method, path, params = {}, pathParams = []) => {
  pathParams.map((item) => {
    path += `/${item}`;
  });
  // console.log(path);
  // console.log('from apiRequest', params);
  const config = {
    method: method,
    url: `${process.env.REACT_APP_API_ENDPOINT}${path}`,
    responseType: 'json',
    ...params,
  };

  try {
    const response = await axios.request(config);
    return response.data;
  } catch (error) {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      const { status, data } = error.response;
      let errorMessage;

      if (status === 400) {
        errorMessage = data?.detail;
      } else if (status === 401) {
        // handle unauthorized error
        errorMessage = `apiRequest ${method} ${path} - You are not authorized to perform this action`;
      } else if (status === 404) {
        // handle not found error
        errorMessage = `apiRequest ${method} ${path} - The resource you are trying to access was not found`;
      } else if (status >= 500) {
        // handle server error
        errorMessage = `apiRequest ${method} ${path} - An error occurred on the server`;
      } else {
        errorMessage = data.message;
      }

      throw new ApiError(error.response, errorMessage);
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(`apiRequest ${method} ${path} - No response was received from the server`);
      throw new Error(`apiRequest ${method} ${path} - No response was received from the server`);
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error(`apiRequest ${method} ${path} -`, error.message);
      throw error;
    }
  }
};

export const defaultQueryFn = async ({ queryKey }) => {
  const data = await apiRequest('get', queryKey[0]);
  return data;
};

export default apiRequest;
