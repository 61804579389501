import { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* Material UI and other UI Dependencies */
import { MenuItem, Typography } from '@mui/material';

// Mui v5
import NotificationsIcon from '@mui/icons-material/Notifications';
import { IconButton, Divider, Box, Tooltip, Badge, Menu, Button } from '@mui/material';

import useNotifications from 'lib/hooks/useNotifications';
import { trackEvent } from 'lib/analytics';

export function monthDay(str) {
  const theDate = new Date(`${str}T00:00:00`);
  return `${theDate.getMonth() + 1}/${theDate.getDate()}/${theDate.getFullYear().toString().slice(2)}`;
}

export default function Notifications({ jobSearchEnabled }) {
  const [notificationsAnchorEl, setNotificationsAnchorEl] = useState(null);
  const open = Boolean(notificationsAnchorEl);
  const [incompleteWeeks, likedSkills, showProfileNotification] = useNotifications();
  const myProfileNotificationShown = localStorage && localStorage.getItem('myProfileNotificationShown');

  const intl = useIntl();
  const history = useHistory();
  const urlPrefix = getUrlPrefix(intl.locale);
  const pendingSkills = likedSkills.length < 10 ? 1 : 0;
  const total = incompleteWeeks.length + pendingSkills + showProfileNotification;

  const handleNotificationsClick = (event) => {
    setNotificationsAnchorEl(event.currentTarget);
  };
  const handleNotificationsClose = () => {
    setNotificationsAnchorEl(null);
  };

  return (
    <>
      <Tooltip title={intl.formatMessage({ id: 'layout.menu.todos' })}>
        <IconButton
          onClick={(e) => {
            handleNotificationsClick(e);
            trackEvent('NOTIFICATIONS_OPEN');
          }}
          size="small"
          sx={{ position: 'relative', top: -1, mr: 2 }}
          aria-controls={open ? 'account-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
        >
          <Badge
            badgeContent={jobSearchEnabled ? total : pendingSkills + showProfileNotification}
            color="error"
            id="to-do-link"
          >
            <NotificationsIcon sx={{ width: 32, height: 32, color: 'white', position: 'relative', right: -2 }} />
          </Badge>
        </IconButton>
      </Tooltip>
      <Menu
        anchorEl={notificationsAnchorEl}
        id="account-menu"
        open={open}
        onClose={handleNotificationsClose}
        onClick={handleNotificationsClose}
        slotProps={{
          paper: {
            elevation: 0,
            sx: {
              overflowY: 'auto',
              maxHeight: '50vh',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              maxWidth: 360,
              zIndex: (theme) => theme.zIndex.modal + 1,
              '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
              },
            },
          },
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <Box sx={{ mx: 3, my: 2 }}>
          <Typography variant="h4" style={{ fontSize: 18 }}>
            {intl.formatMessage({ id: 'layout.menu.todos' })}
          </Typography>
        </Box>
        <Divider />
        {!myProfileNotificationShown && (
          <MenuItem
            sx={{
              py: 2,
              px: 5,
              whiteSpace: 'normal',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
            onClick={() => {
              history.push(`${urlPrefix}/my-profile`);
              handleNotificationsClose();
              if (localStorage) {
                localStorage.setItem('myProfileNotificationShown', 'true');
              }
              trackEvent('NOTIFICATIONS_MY_PROFILE');
            }}
          >
            <Typography style={{ maxWidth: '100%', position: 'relative' }}>
              <span style={{ marginRight: '10px', top: '1px', left: '-16px', position: 'absolute' }}>•</span>
              {intl.formatMessage({ id: 'layout.notifications.lfeLive' }, { b: (chunks) => <b>{chunks}</b> })}
            </Typography>
            <Button variant="outlined" sx={{ fontSize: 14, lineHeight: 1.4, px: 2 }}>
              {intl.formatMessage({ id: 'layout.notifications.visitMyProfile' })}
            </Button>
          </MenuItem>
        )}
        {!myProfileNotificationShown && <Divider />}
        {(jobSearchEnabled ? total : pendingSkills) === 0 && myProfileNotificationShown ? (
          <Box py={3} px={4}>
            <Typography style={{ maxWidth: '100%' }}>
              {intl.formatMessage({ id: 'layout.notifications.caughtUp' })}
            </Typography>
          </Box>
        ) : null}
        {likedSkills.length < 10 && (
          <MenuItem
            sx={{
              py: 2,
              px: 5,
              whiteSpace: 'normal',
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-start',
            }}
            onClick={() => {
              history.push(`${urlPrefix}/experience`);
              handleNotificationsClose();
              trackEvent('NOTIFICATIONS_ADD_SKILLS');
            }}
          >
            <Typography style={{ maxWidth: '100%', position: 'relative' }}>
              <span style={{ marginRight: '10px', top: '1px', left: '-16px', position: 'absolute' }}>•</span>
              {intl.formatMessage({ id: 'layout.notifications.missingSkills' })}
            </Typography>
            <Button variant="outlined" sx={{ fontSize: 14, lineHeight: 1.4, px: 2, mt: 2 }}>
              {intl.formatMessage({ id: 'layout.notifications.updateSkills' })}
            </Button>
          </MenuItem>
        )}
        {jobSearchEnabled && incompleteWeeks.length > 0 && myProfileNotificationShown ? <Divider /> : null}
        {jobSearchEnabled && total > 0
          ? incompleteWeeks.map((week, i, fullArray) => (
              <MenuItem
                key={week.from}
                sx={{
                  py: 2,
                  px: 5,
                  whiteSpace: 'normal',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'flex-start',
                  borderBottom: (theme) =>
                    i !== fullArray.length - 1 ? `solid 1px ${theme.palette.grey['300']}` : 'none',
                }}
                onClick={() => {
                  history.push(`${urlPrefix}/job-search-activity`);
                  handleNotificationsClose();
                  trackEvent('NOTIFICATIONS_WORKSEARCH');
                }}
              >
                <Typography style={{ maxWidth: '100%', position: 'relative' }}>
                  <span style={{ marginRight: '10px', top: '1px', left: '-16px', position: 'absolute' }}>•</span>
                  {intl.formatMessage(
                    { id: 'layout.notifications.completedTasks' },
                    {
                      completed: week.items.filter((i) => !i.draft).length,
                      total: week.required,
                      date: monthDay(week.to),
                    }
                  )}
                </Typography>
                <Box sx={{ mt: 2, mb: i !== fullArray.length - 1 ? 3 : 0 }}>
                  <Button variant="outlined" sx={{ fontSize: 14, lineHeight: 1.4, px: 2 }}>
                    {intl.formatMessage({ id: 'layout.menu.updateActivity' })}
                  </Button>
                </Box>
              </MenuItem>
            ))
          : null}
      </Menu>
    </>
  );
}

Notifications.propTypes = {
  jobSearchEnabled: PropTypes.bool,
};
