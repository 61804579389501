import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import TuneOutlinedIcon from '@mui/icons-material/TuneOutlined';
import { grey } from '@mui/material/colors';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';

/* i18n */
import { useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* API */
import { apiRequest } from 'shared/API';
import { useQuery } from '@tanstack/react-query';

/* Redux */
import { useSelector, useDispatch } from 'react-redux';
import {
  setTagsActive,
  setSalaryRangesFilter,
  setCareerOutlookFilter,
  setSkillsFilter,
  setRequirementsFilter,
  setFilterOptions,
  setShowRecommendedCareers,
} from 'app/slices/careersSlice';
import {
  setActiveSoc,
  setActiveCareer,
  setCareerPathsFilterValues,
  setSkillsFilter as setJobsSkillsFilter,
  setEmployerFilter,
  setSalaryRangesFilter as setJobsSalaryRangesFilter,
  setLocationsFilterValues,
} from 'app/slices/jobsSlice';
import {
  setCareerPathsFilterValues as setTrainingsCareerFilter,
  setSkillsFilter as setTrainingsSkillsFilter,
  setProvidersFilter,
  setLocationsFilterValues as setTrainingsLocationsFilterValues,
} from 'app/slices/trainingSlice';

import { useRateCareer } from 'shared/hooks';
import { useInfiniteCareers } from 'lib/hooks/careers';
/* Analytics */
import { trackEvent } from 'lib/analytics';
import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import {
  Box,
  Container,
  CircularProgress,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormControlLabel,
  Switch,
} from '@mui/material';
import { Alert } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import { useSnackbar } from 'notistack';
import { CustomNotification, JobNotificationContents } from 'shared/components';
import SkillsMultiselectFilter from 'shared/src/components/SkillsMultiselectFilter';
import MultiselectFilter from 'shared/src/components/MultiselectFilter';
import CareersCard from 'shared/src/components/CareersCard';
import FeatureRequestSurveyScript from './FeatureRequestSurveyScript';

const Careers = () => {
  const {
    skillsFilter,
    tagsActive,
    salaryRangesFilter,
    careerOutlookFilter,
    requirementsFilter,
    showRecommendedCareers,
    filterOptions: { skills },
  } = useSelector((state) => state.careers);
  const filters = {
    filter_by_skills: skillsFilter,
    filter_by_tag: tagsActive.filter((t) => t !== 'jobGrowth'),
    filter_by_avg_salary: salaryRangesFilter,
    filter_by_reqs: requirementsFilter,
    bright_outlook: tagsActive.length && tagsActive.includes('jobGrowth'),
    all_careers: !showRecommendedCareers,
  };

  const filtersQuery = useQuery({
    queryKey: ['/users/me/careers_filters', showRecommendedCareers],
    queryFn: async ({ queryKey }) =>
      apiRequest('GET', queryKey[0], {
        params: { ...(!showRecommendedCareers ? { all_careers: !showRecommendedCareers } : {}) },
      }),
    onSuccess: (data) => {
      dispatch(
        setFilterOptions({
          skills: data.skills,
        })
      );
    },
  });
  const { isSuccess, data, isFetching, isFetched, hasNextPage, fetchNextPage } = useInfiniteCareers(filters);
  const { isSuccess: isDefaultSucces, data: defaultData } = useInfiniteCareers({});
  const [loadedFirstTime, setLoadedFirstTime] = useState(false);
  const [filtersOpen, setFiltersOpen] = useState(false);
  const [activeVideo, setActiveVideo] = useState(null);
  const rateCareer = useRateCareer(filters);
  const { loadingLanguage } = useSelector((state) => state.app);
  const dispatch = useDispatch();
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();

  const intl = useIntl();
  const urlPrefix = getUrlPrefix(intl.locale);
  const { currentSoc } = useSelector((state) => state.jobs);

  useEffect(() => {
    if (isSuccess) {
      setLoadedFirstTime(true);
    }
  }, [isSuccess]);

  const results = isSuccess
    ? data.pages.flat(1).map((c) => {
        if (c.soc === currentSoc) {
          return { ...c, flag: 'currentCareer' };
        }
        return c;
      })
    : [];

  const filtersActive =
    (!filtersQuery.isLoading &&
      [skillsFilter, tagsActive, salaryRangesFilter, requirementsFilter].some((arr) => arr.length > 0)) ||
    careerOutlookFilter;
  const filtersCount =
    [skillsFilter, tagsActive, salaryRangesFilter, requirementsFilter].reduce((acc, curr) => acc + curr.length, 0) +
    (careerOutlookFilter ? 1 : 0);

  const showMore = () => {
    trackEvent('CAREERS_MORE');
    fetchNextPage();
  };

  const handleScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight - 400 ||
      isFetching ||
      !hasNextPage
    ) {
      return;
    }
    showMore();
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isFetching]);

  const clearFilters = () => {
    dispatch(setTagsActive([]));
    dispatch(setSalaryRangesFilter([]));
    dispatch(setCareerOutlookFilter(false));
    dispatch(setRequirementsFilter([]));
    dispatch(setSkillsFilter([]));
  };

  const showJobs = (item) => {
    const { soc: soc_code } = item;
    dispatch(setActiveSoc(soc_code));
    dispatch(setActiveCareer(item));
    dispatch(setJobsSkillsFilter([]));
    dispatch(setEmployerFilter([]));
    dispatch(setJobsSalaryRangesFilter([]));
    dispatch(setLocationsFilterValues([]));

    history.push(`${urlPrefix}/jobs`);
    if (window) {
      window.scrollTo({ top: 0 });
    }
  };

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'careers.pageTitle' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('CAREERS');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  const handleShowJobsClick = (idx, item) => () => {
    dispatch(setCareerPathsFilterValues([item?.soc]));
    trackEvent('CAREERS_GLOBAL_SEEJOBS', item);

    if (idx === 0) {
      trackEvent('CAREERS_TOPCAREER_SEEJOBS', item);
    } else if (idx < 10) {
      trackEvent('CAREERS_GOAL_SEEJOBS', item);
    } else {
      trackEvent('CAREERS_MORE_SEEJOBS', item);
    }

    showJobs(item);
  };

  const handleShowTrainingsClick = (idx, item) => () => {
    dispatch(setTrainingsCareerFilter([item?.soc?.slice(0, 3)]));
    dispatch(setTrainingsSkillsFilter([]));
    dispatch(setProvidersFilter([]));
    dispatch(setLocationsFilterValues([]));
    dispatch(setTrainingsLocationsFilterValues([]));

    trackEvent('CAREERS_GLOBAL_SEETRAININGS', item);

    if (idx === 0) {
      trackEvent('CAREERS_TOPCAREER_SEETRAININGS', item);
    } else if (idx < 10) {
      trackEvent('CAREERS_GOAL_SEETRAININGS', item);
    } else {
      trackEvent('CAREERS_MORE_SEETRAININGS', item);
    }

    history.push(`${urlPrefix}/training`);
    if (window) {
      window.scrollTo({ top: 0 });
    }
  };

  const handleLikeClick = (idx, career) => () => {
    const newRating = career.rating === 1 ? 0 : 1;

    rateCareer.mutate(
      { career, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
        onSuccess: () => {
          if (newRating === 0) return;
          enqueueSnackbar('', {
            variant: 'default',
            content: (key) => (
              <CustomNotification id={key} check>
                <JobNotificationContents rate="like" intl={intl} history={history} redirect="saved-items" />
              </CustomNotification>
            ),
          });
        },
      }
    );

    if (newRating === 1) {
      trackEvent('CAREERS_GLOBAL_LIKE', career);
      if (idx === 0) {
        trackEvent('CAREERS_TOPCAREER_LIKE', career);
      } else if (idx >= 10) {
        trackEvent('CAREERS_MORE_LIKE', career);
      }
    }
  };

  const handleDislikeClick = (idx, career) => () => {
    const newRating = career.rating === -1 ? 0 : -1;

    rateCareer.mutate(
      { career, rating: newRating },
      {
        onError: () => {
          enqueueSnackbar(intl.formatMessage({ id: 'app.error' }), { variant: 'error' });
        },
        onSuccess: () => {
          enqueueSnackbar('', {
            variant: 'default',
            content: (key) => (
              <CustomNotification id={key} check>
                {intl.formatMessage(
                  { id: 'ratings.dislikedItem' },
                  {
                    link: (
                      <Typography
                        component="span"
                        sx={{
                          pb: 0.5,
                          '&:active': { color: grey[100] },
                          fontSize: '0.875rem',
                          cursor: 'pointer',
                          textDecoration: 'underline',
                        }}
                        onClick={() => history.push(`${urlPrefix}/hidden-items`)}
                      >
                        {intl.formatMessage({ id: 'ratings.dislikedItem.here' })}
                      </Typography>
                    ),
                  }
                )}
              </CustomNotification>
            ),
          });
        },
      }
    );

    if (newRating === -1) {
      trackEvent('CAREERS_GLOBAL_DISLIKE', career);
      if (idx === 0) {
        trackEvent('CAREERS_TOPCAREER_DISLIKE', career);
      } else if (idx >= 10) {
        trackEvent('CAREERS_MORE_DISLIKE', career);
      }
    }
  };

  const showResults = !loadingLanguage && isFetched && results && results.length > 0;
  const showNoResults = !loadingLanguage && isFetched && results && results.length === 0 && isDefaultSucces;

  const FiltersList = (
    <Box sx={{ width: 264, px: 1.5 }} role="presentation">
      <List>
        <ListItem sx={{ pt: 1.5, pb: 1, pl: 0 }}>
          <Typography variant="h5">{intl.formatMessage({ id: 'jobs.filters.filters' })}</Typography>
        </ListItem>
      </List>
      <Divider />
      <List>
        <ListItem sx={{ px: 0.5 }}>
          <FormControlLabel
            sx={{ ml: 0 }}
            labelPlacement="start"
            control={
              <Switch
                checked={showRecommendedCareers}
                onChange={() => {
                  clearFilters();
                  dispatch(setShowRecommendedCareers(!showRecommendedCareers));
                }}
              />
            }
            label={intl.formatMessage({ id: 'careers.filters.showRecommendedCareers' })}
          />
        </ListItem>

        <ListItem sx={{ px: 0.5, pt: 0.5 }}>
          <Typography variant="h5">{intl.formatMessage({ id: 'careers.filters.goals.label' })}</Typography>
        </ListItem>
        <ListItem disablePadding>
          <MultiselectFilter
            label={intl.formatMessage({ id: 'careers.filters.goals' })}
            options={[
              {
                value: 'boostEarnings',
                label: intl.formatMessage({ id: 'careers.filters.goals.boostIncome' }),
                id: 'some-id',
              },
              { value: 'manyJobs', label: intl.formatMessage({ id: 'careers.filters.goals.highDemand' }) },
              {
                value: 'jobGrowth',
                label: intl.formatMessage({ id: 'careers.filters.goals.projectedGrowth' }),
              },
            ]}
            value={tagsActive}
            onChange={(newValues) => dispatch(setTagsActive(newValues))}
            id="goals-careers-dropdown"
          />
        </ListItem>
        <ListItem sx={{ px: 0.5, pt: 0.5 }}>
          <Typography variant="h5">{intl.formatMessage({ id: 'jobs.filters.salary' })}</Typography>
        </ListItem>
        <ListItem disablePadding>
          <FormControl fullWidth sx={{ '.MuiInputBase-input': { py: '11px' } }}>
            <InputLabel id="salary-select-label">
              {intl.formatMessage({ id: 'careers.filters.salaryRange' })}
            </InputLabel>
            <Select
              labelId="salary-select-label"
              value={salaryRangesFilter}
              label={intl.formatMessage({ id: 'careers.filters.salaryRange' })}
              sx={{ width: '100%' }}
              onChange={(evt) => {
                const newValue = evt.target.value;
                dispatch(setSalaryRangesFilter(newValue ? [newValue] : []));
              }}
              id="salary-careers-dropdown"
            >
              <MenuItem value="">
                <em>{intl.formatMessage({ id: 'careers.filters.salary.none' })}</em>
              </MenuItem>
              <MenuItem value="10000_plus">$10,000+</MenuItem>
              <MenuItem value="25000_plus">$20,000+</MenuItem>
              <MenuItem value="50000_plus">$50,000+</MenuItem>
              <MenuItem value="75000_plus">$75,000+</MenuItem>
              <MenuItem value="100000_plus">$100,000+</MenuItem>
              <MenuItem value="150000_plus">$150,000+</MenuItem>
            </Select>
          </FormControl>
        </ListItem>
        <ListItem sx={{ px: 0.5, pt: 0.5 }}>
          <Typography variant="h5">{intl.formatMessage({ id: 'training.filters.preReqs' })}</Typography>
        </ListItem>
        <ListItem disablePadding>
          <MultiselectFilter
            label={intl.formatMessage({ id: 'careers.filters.entryReqs' })}
            options={[
              { value: 'no_exp', label: intl.formatMessage({ id: 'careers.filters.reqs.noExperience' }) },
              { value: 'high_school', label: intl.formatMessage({ id: 'careers.filters.reqs.highSchoolDiploma' }) },
              { value: 'bachelor_degree', label: intl.formatMessage({ id: 'careers.filters.reqs.bachelorDegree' }) },
              {
                value: 'training_available',
                label: intl.formatMessage({ id: 'careers.filters.reqs.onTheJobTraining' }),
              },
            ]}
            value={requirementsFilter}
            onChange={(newValues) => dispatch(setRequirementsFilter(newValues))}
            id="prerequisites-careers-dropdown"
          />
        </ListItem>
        <ListItem sx={{ px: 0.5 }}>
          <Typography variant="h5">
            {intl.formatMessage({ id: 'experience.drawer.skillPlural' })} ({skills.length})
          </Typography>
        </ListItem>
        <ListItem disablePadding>
          <SkillsMultiselectFilter
            value={skillsFilter}
            enabledSkills={skills}
            onChange={(newValues) => dispatch(setSkillsFilter(newValues))}
            id="skills-careers-dropdown"
          />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <Container maxWidth="lg">
      <FeatureRequestSurveyScript />
      <Box
        sx={{
          mt: 6,
          mb: 2.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: { md: 'flex-end' },
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Box>
          <Typography variant="h1">
            <WorkTwoToneIcon sx={{ fontSize: 24, position: 'relative', top: 2 }} />{' '}
            {intl.formatMessage({ id: 'careers.title' })}
          </Typography>
          <Typography variant="body2">{intl.formatMessage({ id: 'careers.subtitle' })}</Typography>
        </Box>

        {!loadingLanguage && loadedFirstTime && (
          <Button
            variant="outlined"
            color="primary"
            sx={{ mt: { xs: 1, md: 0 }, textTransform: 'none', fontSize: 14 }}
            onClick={() => setFiltersOpen(true)}
            startIcon={<TuneOutlinedIcon />}
            id="filter-career-button"
          >
            {intl.formatMessage({ id: 'jobs.filters.filters' })}
            {filtersCount > 0 ? <span style={{ fontSize: 13 }}>({filtersCount})</span> : null}
          </Button>
        )}
      </Box>
      <Divider sx={{ mt: 2, mb: 3 }} />
      {(isFetching && !isFetched) || loadingLanguage || !filtersQuery.isFetched ? (
        <Box m={2} display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) : null}
      <Grid container spacing={2}>
        {!loadingLanguage && isFetched && filtersActive && results.length === 0 && (
          <Grid item xs={12} mb={1}>
            <Alert severity="warning" sx={{ mt: 1, mb: 2 }}>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                {intl.formatMessage(
                  { id: 'careers.filters.noResults' },
                  {
                    link: (
                      <Typography
                        sx={{
                          color: 'primary.dark',
                          fontWeight: 700,
                          cursor: 'pointer',
                          '&:hover': { textDecoration: 'underline' },
                        }}
                        onClick={clearFilters}
                        component="span"
                      >
                        {intl.formatMessage({ id: 'careers.filters.noResultsLink' })}
                      </Typography>
                    ),
                  }
                )}
              </Typography>
            </Alert>
          </Grid>
        )}
        {showResults &&
          results.map((item, idx) => (
            <CareersCard
              item={item}
              key={item.id}
              rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
              onButtonClick={handleShowJobsClick(idx, item)}
              onTrainingsButtonClick={handleShowTrainingsClick(idx, item)}
              onLikeClick={handleLikeClick(idx, item)}
              onDislikeClick={handleDislikeClick(idx, item)}
              tagsActive={tagsActive}
              isVideoPlaying={activeVideo === item.id}
              onVideoPlay={() => setTimeout(() => setActiveVideo(item.id), 100)}
            />
          ))}
        {showNoResults &&
          defaultData.pages
            .flat(1)
            .slice(0, 5)
            .map((item, idx) => (
              <CareersCard
                item={item}
                key={item.id}
                rating={item.rating === 1 ? 'liked' : item.rating === -1 ? 'disliked' : null}
                onButtonClick={handleShowJobsClick(idx, item)}
                onTrainingsButtonClick={handleShowTrainingsClick(idx, item)}
                onLikeClick={handleLikeClick(idx, item)}
                onDislikeClick={handleDislikeClick(idx, item)}
                tagsActive={tagsActive}
                isVideoPlaying={activeVideo === item.id}
                onVideoPlay={() => setTimeout(() => setActiveVideo(item.id), 100)}
              />
            ))}
      </Grid>
      <Box sx={{ display: 'flex', justifyContent: 'center' }} py={4}>
        {!(filtersActive && results.length === 0) && hasNextPage && !loadingLanguage && isFetched && isFetching ? (
          <CircularProgress />
        ) : (
          <span />
        )}
      </Box>
      <Drawer anchor="right" open={filtersOpen} onClose={() => setFiltersOpen(false)}>
        {FiltersList}
      </Drawer>
    </Container>
  );
};

export default Careers;
