/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';
import { getUrlPrefix } from 'i18n/config';

/* Styles */
import styles from 'styles/layout.module.scss';
import cssVars from 'styles/vars.module.scss';

/* Material UI and other UI Dependencies */
import { Container, Typography } from '@mui/material';
import ScrollToTopButton from 'components/ScrollToTopRound';
import { Box, Grid } from '@mui/material';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import Link from '@mui/material/Link';
import { grey } from '@mui/material/colors';

/* Analytics */
import { trackEvent } from 'lib/analytics';

const Footer = () => {
  const intl = useIntl();
  const urlPrefix = getUrlPrefix(intl.locale);
  if (cssVars.stateSealHref) {
    cssVars.stateSealHref = cssVars.stateSealHref.replace(/['"]+/g, '');
  }

  return (
    <Box
      sx={{
        color: cssVars.white,
        paddingTop: '26px',
        paddingBottom: '34px',
        position: 'relative',
        bgcolor: 'secondary.dark',
      }}
    >
      <Container maxWidth="lg">
        <Grid container className={styles.footerContent} spacing={0}>
          <Grid item xs={12} sm={4} sx={{ justifyContent: { xs: 'center', sm: 'flex-start' } }}>
            <Box display="flex" px={4} py={1}>
              <img
                src="/images/ar-state-gov-logo.png"
                style={{ width: 130 }}
                alt={intl.formatMessage({ id: 'homepage.imgAlt.arState' })}
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display="flex" justifyContent="space-around" px={4} pt={2} pb={1}>
              <Grid container>
                <Grid item xs={12} className={styles.footerText} sx={{ mb: 2 }}>
                  <Typography variant="h4" component="h2" style={{ color: cssVars.white }}>
                    {intl.formatMessage({ id: 'layout.footer.aboutAR' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={styles.footerText}>
                  <Link
                    rel="noreferrer noopener"
                    sx={{ pb: 0.5, '&:active': { color: grey[100] } }}
                    href={`${urlPrefix}/about`}
                    target="_blank"
                    onClick={() => trackEvent('FOOTER_ABOUT')}
                    id="about-launch-footer-public-link"
                  >
                    <FormattedMessage id="layout.footer.learnAbout" />
                  </Link>
                </Grid>
                <Grid item xs={12} className={styles.footerText}>
                  <Link
                    rel="noreferrer noopener"
                    sx={{ pb: 0.5, '&:active': { color: grey[100] } }}
                    href="https://myardashboard.arkansas.gov/workforce.html"
                    target="_blank"
                    onClick={() => trackEvent('FOOTER_DASHBOARD')}
                    id="workforce-dashboard-footer-public-link"
                  >
                    <FormattedMessage id="layout.footer.arWorkforceDashboard" />{' '}
                    <OpenInNewIcon sx={{ fontSize: 14, position: 'relative', top: 2 }} />
                  </Link>
                </Grid>
                <Grid item xs={12} className={styles.footerText}>
                  <Link
                    rel="noreferrer noopener"
                    sx={{ pb: 0.5, '&:active': { color: grey[100] } }}
                    href={`${urlPrefix}/credits-and-citations`}
                    target="_blank"
                    onClick={() => trackEvent('FOOTER_CREDITS')}
                    id="credits-footer-public-link"
                  >
                    <FormattedMessage id="layout.footer.credits" />
                  </Link>
                </Grid>
                <Grid item xs={12} className={styles.footerText}>
                  <Link
                    rel="noreferrer noopener"
                    sx={{ pb: 0.5, '&:active': { color: grey[100] } }}
                    href={`${urlPrefix}/terms-of-use`}
                    target="_blank"
                    onClick={() => trackEvent('FOOTER_TERMS')}
                  >
                    <FormattedMessage id="layout.footer.privacy" />
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Box display="flex" justifyContent="space-around" px={4} pt={2} pb={1}>
              <Grid container>
                <Grid item xs={12} className={styles.footerText} sx={{ mb: 2 }}>
                  <Typography variant="h4" component="h2" style={{ color: cssVars.white }}>
                    {intl.formatMessage({ id: 'layout.footer.contact' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={styles.footerText}>
                  <Typography sx={{ color: grey[100], '&:active': { color: grey[100] }, fontSize: 14 }}>
                    {intl.formatMessage({ id: 'layout.footer.reemploymentQuestions' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={styles.footerText}>
                  <Link
                    sx={{ pb: 1, '&:active': { color: grey[100] } }}
                    href="tel:18449082178"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackEvent('FOOTER_PHONE')}
                  >
                    1-844-908-2178
                  </Link>
                </Grid>
                <Grid item xs={12} className={styles.footerText}>
                  <Typography sx={{ color: grey[100], '&:active': { color: grey[100] }, fontSize: 14 }}>
                    {intl.formatMessage({ id: 'layout.footer.launchQuestions' })}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={styles.footerText}>
                  <Link
                    sx={{ pb: 0.5, '&:active': { color: grey[100] } }}
                    href="tel:18004357989"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => trackEvent('FOOTER_PHONE')}
                  >
                    1-800-435-7989
                  </Link>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={5}
            display="flex"
            sx={{
              justifyContent: { xs: 'center', sm: 'flex-end' },
              pr: { sm: 4 },
              alignItems: { sm: 'flex-end' },
              pb: { sm: 1.8 },
              pt: { xs: 4, sm: 0 },
            }}
          >
            <ScrollToTopButton />
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default Footer;
