import { useState } from 'react';
import { Formik } from 'formik';
import { useHistory } from 'react-router-dom';
import { preventSubmitOnEnter } from 'shared/utils';
import { trackEvent } from 'lib/analytics';
import { pathOr } from 'ramda';

/* Styles */
import styles from 'styles/login.module.scss';
import layoutStyles from 'styles/layout.module.scss';

/* i18n */
import { useIntl } from 'react-intl';

/* API */
import { updateExperience } from 'shared/src/lib/API/experience';

/* Material UI and other UI Dependencies */
import { Typography } from '@mui/material';
import OnboardingSteps from 'components/OnboardingSteps';
import { Box, FormControl, FormHelperText, useMediaQuery, Button, InputLabel, Select, MenuItem } from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';

export default function OnboardingEducation() {
  const isSmall = useMediaQuery('(max-width:767px)');
  const [submitting, setSubmitting] = useState(false);

  const queryClient = useQueryClient();

  const experienceQuery = useQuery({
    queryKey: ['/users/me/experience/'],
  });

  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const history = useHistory();
  const educationOptions = [
    {
      value: '11',
      title: intl.formatMessage({ id: 'forms.onboarding.education.noDiploma' }),
      ddId: 'less-than-high-school-onboard-dropdown',
    },
    {
      value: '11.5',
      title: intl.formatMessage({ id: 'forms.onboarding.education.someHighSchool' }),
      ddId: 'some-high-school-onboard-dropdown',
    },
    {
      value: '12',
      title: intl.formatMessage({ id: 'forms.onboarding.education.diploma' }),
      ddId: 'high-school-onboard-dropdown',
    },
    {
      value: '13',
      title: intl.formatMessage({ id: 'forms.onboarding.education.someCollege' }),
      ddId: 'some-college-onboard-dropdown',
    },
    {
      value: '14',
      title: intl.formatMessage({ id: 'forms.onboarding.education.assocDegree' }),
      ddId: 'associates-degree-onboard-dropdown',
    },
    {
      value: '16',
      title: intl.formatMessage({ id: 'forms.onboarding.education.bachDegree' }),
      ddId: 'bachelors-degree-onboard-dropdown',
    },
    {
      value: '16.5',
      title: intl.formatMessage({ id: 'forms.onboarding.education.someGraduate' }),
      ddId: 'some-gradschool-onboard-dropdown',
    },
    {
      value: '17',
      title: intl.formatMessage({ id: 'forms.onboarding.education.mastersDegree' }),
      ddId: 'masters-degree-onboard-dropdown',
    },
    {
      value: '20',
      title: intl.formatMessage({ id: 'forms.onboarding.education.advancedDegree' }),
      ddId: 'doctorate-professionl-onboard-dropdown',
    },
  ];

  const initialValues = {
    degree: pathOr('', ['education', 0, 'degree'], experienceQuery.data),
  };

  return (
    <Box sx={{ boxShadow: '0px 3px 12px rgba(0, 0, 0, 0.1)' }}>
      <div className={styles.loginFlow__form__onboarding}>
        <Box px={3}>
          <Typography align="center" variant="h1" gutterBottom sx={{ fontSize: 24, mt: 1 }}>
            {intl.formatMessage({ id: 'onboarding.title' }, { step: 3, totalSteps: 5 })}
          </Typography>
        </Box>
        <Box sx={{ maxWidth: 222, marginLeft: 'auto', marginRight: 'auto', mt: 4, mb: 1 }}>
          <OnboardingSteps activeStep={3} />
        </Box>
      </div>
      <hr className={layoutStyles.separatorBold} />
      <Box className={styles.onboardingExperience__cardsContainer} sx={{ maxWidth: 500, margin: 'auto' }}>
        <Formik
          initialValues={initialValues}
          validate={(values) => {
            const errors = {};
            if (!values.degree) {
              errors.degree = intl.formatMessage({ id: 'forms.onboarding.educationRequired' });
            }
            return errors;
          }}
          onSubmit={(values) => {
            setSubmitting(true);

            updateExperience({ education: [{ degree: values.degree }] })
              .then(() => {
                setSubmitting(false);
                queryClient.invalidateQueries(['/users/me/experience/']);
                history.push(`${urlPrefix}/onboarding/location`);
              })
              .catch(() => {
                setSubmitting(false);
              });
            trackEvent('ONBOARDING_EDUCATION_NEXT');
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            /* and other goodies */
          }) => (
            <form onSubmit={handleSubmit}>
              <Typography align="center" variant="h5" gutterBottom px={{ xs: 1, md: 6 }}>
                {intl.formatMessage({ id: 'forms.onboarding.education.addSubtitle' })}
              </Typography>
              <Box mb={3} />
              <Box sx={{ display: 'flex', flexDirection: 'column' }}>
                {/* Education Level */}
                <Box sx={{ display: 'flex', mb: 1, flexDirection: 'column', order: values.type === 'degree' ? 1 : 5 }}>
                  <Typography sx={{ fontWeight: 700 }}>
                    {intl.formatMessage({
                      id: 'forms.onboarding.education.add',
                    })}
                    <Typography sx={{ color: (theme) => theme.palette.error.main }} component="span">
                      *
                    </Typography>
                  </Typography>
                  <FormControl variant="filled" error={!!touched.degree && !!errors.degree}>
                    <InputLabel id="education-level-label" htmlFor="education-level-input-id">
                      {intl.formatMessage({
                        id: `forms.onboarding.educationLevel.${values.type === 'degree' ? 'alt' : 'titlecase'}`,
                      })}
                    </InputLabel>
                    <Select
                      name="degree"
                      labelId="education-level-label"
                      id="education-level"
                      value={values.degree}
                      onChange={(e) => {
                        handleChange(e);
                        trackEvent('ONBOARDING_EDUCATION_LEVEL', e.target.value);
                      }}
                      onKeyDown={preventSubmitOnEnter}
                      inputProps={{
                        id: 'education-level-input-id',
                      }}
                      MenuProps={{
                        disableScrollLock: true,
                        PaperProps: {
                          variant: 'outlined',
                        },
                        sx: {
                          maxHeight: 440,
                          '& .MuiPaper-root': {
                            borderRight: '1px solid #ccc', // Set the border style and color
                            borderBottom: '1px solid #ccc', // Set the border style and color
                            borderLeft: '1px solid #ccc', // Set the border style and color
                          },
                        },
                      }}
                      sx={{
                        '.MuiFilledInput-input': { borderRadius: '4px 4px 0 0 !important' },
                        '.MuiFilledInput-input:not(.Mui-disabled):not(:hover)': { backgroundColor: 'white' },
                      }}
                    >
                      {educationOptions
                        .filter((opt) => (values.type === 'degree' ? opt.value > 12 : true))
                        .map((eo) => (
                          <MenuItem key={eo.value} value={eo.value} id={eo.ddId}>
                            {eo.title}
                          </MenuItem>
                        ))}
                    </Select>
                    {!!touched.degree && !!errors.degree && (
                      <FormHelperText>{errors.degree ? intl.formatMessage({ id: errors.degree }) : ''}</FormHelperText>
                    )}
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  display: 'flex',
                  marginTop: isSmall ? 10 : 0,
                  justifyContent: isSmall ? 'space-between' : 'flex-end',
                }}
              >
                <Button
                  color={'secondary'}
                  disabled={submitting}
                  size="large"
                  variant="contained"
                  style={{ marginRight: 10 }}
                  onClick={() => {
                    history.push(`${urlPrefix}/onboarding/experience`);
                  }}
                  id="back-onboard-step-3-button"
                >
                  {intl.formatMessage({ id: 'forms.onboarding.back' })}
                </Button>

                <Button
                  color={'secondary'}
                  disabled={submitting}
                  size="large"
                  type="submit"
                  variant="contained"
                  id="next-onboard-step-3-button"
                >
                  {submitting ? (
                    <div className="spinner-border text-primary" role="status">
                      <span className="sr-only">{intl.formatMessage({ id: 'forms.signIn.loading' })}</span>
                    </div>
                  ) : (
                    intl.formatMessage({ id: 'forms.onboarding.next' })
                  )}
                </Button>
              </Box>
            </form>
          )}
        </Formik>
      </Box>
    </Box>
  );
}
