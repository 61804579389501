import { useEffect, useState } from 'react';
import { formatDashboardData } from 'lib/uiDashboard';
import { useHistory } from 'react-router-dom';

/* i18n */
import { FormattedMessage, useIntl } from 'react-intl';

/* API */
import { getUiDashboard } from 'lib/API';

/* Redux */
import { useDispatch } from 'react-redux';
import { setSelectedClaim, setClaims } from 'app/slices/uiSlice';

import TimeMe from 'timeme.js';

/* Material UI and other UI Dependencies */
import {
  Box,
  Container,
  CircularProgress,
  Typography,
  Grid,
  Button,
  Card,
  CardContent,
  Stack,
  lighten,
  Alert,
  AlertTitle,
  Divider,
} from '@mui/material';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { grey } from '@mui/material/colors';

const UIDashboard = () => {
  const [isFetching, setIsFetching] = useState(false);
  const [dashboardData, setDashboardData] = useState();

  const intl = useIntl();
  const urlPrefix = intl.locale === 'en' ? '' : `/${intl.locale}`;
  const dispatch = useDispatch();
  let history = useHistory();

  useEffect(() => {
    setIsFetching(true);

    getUiDashboard().then((response) => {
      const formattedData = formatDashboardData(response.data);
      setDashboardData(formattedData);
      setIsFetching(false);
    });
  }, []);

  useEffect(() => {
    document.title = intl.formatMessage({ id: 'uiDashboard.yourActivity' });
    TimeMe.stopTimer();
    TimeMe.setCurrentPageName('UI_DASHBOARD');
    TimeMe.startTimer();
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }, 0);
  }, []);

  if (isFetching) {
    return (
      <Box mt={6} mb={2.5} display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );
  }

  const handleFileClaim = ({ week_start, week_end, activities }) => {
    dispatch(
      setSelectedClaim({
        weekStart: week_start,
        weekEnd: week_end,
        activities,
      })
    );
    dispatch(setClaims(dashboardData.claim_week));
    history.push(`${urlPrefix}/ui-law-changes`);
  };

  return (
    <Container maxWidth="lg">
      <Box
        sx={{
          mt: 6,
          mb: 2.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: { md: 'flex-end' },
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Typography variant="h1">
          <CalendarMonthOutlinedIcon sx={{ fontSize: 24, position: 'relative', top: 2 }} />{' '}
          {intl.formatMessage({ id: 'layout.menu.uidashboard' })}
        </Typography>
      </Box>
      <Divider sx={{ mt: 2, mb: 3 }} />
      {dashboardData?.claim_week?.length > 0 ? (
        <Alert severity="warning" sx={{ mt: 3 }}>
          <AlertTitle color="inherit" sx={{ fontWeight: 'bold' }}>
            {intl.formatMessage({ id: 'uiDashboard.orderOfFilingText.title' })}
          </AlertTitle>
          <FormattedMessage
            id="uiDashboard.orderOfFilingText"
            values={{
              n: dashboardData?.claim_week?.length,
              start: dashboardData?.claim_week[0].week_start,
              end: dashboardData?.claim_week[0].week_end,
              b: (chunks) => <b>{chunks}</b>,
            }}
          />
        </Alert>
      ) : null}
      <Box
        sx={{
          mt: dashboardData?.claim_week?.length > 0 ? 3 : 6,
          mb: 2.5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: { md: 'flex-end' },
          flexDirection: { xs: 'column', md: 'row' },
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={12} md={8}>
            {dashboardData?.claim_week && (
              <>
                <Box
                  component="section"
                  m="1"
                  sx={{ p: 2, borderRadius: '4px 4px 0px 0px', bgcolor: 'secondary.main' }}
                >
                  <Stack direction="row">
                    <FormatListBulletedOutlinedIcon sx={{ fontSize: 22, color: 'white' }} />
                    <Typography component="h2" sx={{ fontWeight: 700, pl: 1, color: 'white' }}>
                      {intl.formatMessage({ id: 'uiDashboard.yourActivity' })}
                    </Typography>
                  </Stack>
                </Box>
                <Box
                  borderRadius="0px 0px 4px 4px"
                  sx={{ padding: '16px', bgcolor: (theme) => lighten(theme.palette.secondary.main, 0.93) }}
                >
                  {dashboardData?.claim_week.length === 0 ? (
                    <Typography component={'span'} variant="h6" align="center">
                      {intl.formatMessage({ id: 'uiDashboard.noPendingClaims' })}
                    </Typography>
                  ) : (
                    dashboardData?.claim_week.map((claimWeek, index) => (
                      <div key={`login_terms_${claimWeek.id}`}>
                        <Card
                          sx={{
                            mt: 1,
                            borderRadius: 0.5,
                            borderColor: grey['200'],
                            borderStyle: 'solid',
                            borderWidth: 1,
                          }}
                          elevation={0}
                        >
                          <CardContent>
                            <Typography component={'span'} variant="h6" align="center">
                              {claimWeek.days_left}
                            </Typography>
                            <Typography component={'span'} variant="body1">
                              {' '}
                              {intl.formatMessage({ id: 'uiDashboard.daysLeftToCertify' })}{' '}
                            </Typography>
                            <Typography component={'span'} variant="h6" align="center">
                              {`${claimWeek.week_start} - ${claimWeek.week_end}`}
                            </Typography>
                            <Box sx={{ mt: 1, display: 'flex', justifyContent: { xs: 'center', lg: 'flex-end' } }}>
                              <Button
                                disabled={index !== 0}
                                variant="contained"
                                onClick={() => handleFileClaim(claimWeek)}
                                id={`file-a-claim-UI-claim-button-${index}`}
                              >
                                {intl.formatMessage({ id: 'uiDashboard.fileContinuedClaim' })}
                              </Button>
                            </Box>
                          </CardContent>
                        </Card>
                      </div>
                    ))
                  )}
                </Box>
              </>
            )}
          </Grid>

          <Grid item xs={12} md={4}>
            {dashboardData?.summary?.benefits_amount && (
              <>
                <Box
                  component="section"
                  m="1"
                  sx={{ p: 2, borderTopLeftRadius: 4, borderTopRightRadius: 4, bgcolor: 'secondary.main' }}
                >
                  <Stack direction="row">
                    <CalendarMonthOutlinedIcon sx={{ fontSize: 22, color: 'white' }} />
                    <Typography component="h2" sx={{ fontWeight: 700, pl: 1, color: 'white' }}>
                      {intl.formatMessage({ id: 'uiDashboard.benefitsSummary' })}
                    </Typography>
                  </Stack>
                </Box>
                <Box
                  borderRadius={'0px 0px 4px 4px'}
                  sx={{ p: 2.5, bgcolor: (theme) => lighten(theme.palette.secondary.main, 0.93) }}
                >
                  {dashboardData?.summary?.benefits_amount && (
                    <Card
                      sx={{
                        mt: 1,
                        borderRadius: 0.5,
                        borderColor: grey['200'],
                        borderStyle: 'solid',
                        borderWidth: 1,
                      }}
                      elevation={0}
                    >
                      <CardContent>
                        <Typography gutterBottom align="center" sx={{ letterSpacing: '0.15px', pt: 1, pb: 0.5 }}>
                          {intl.formatMessage({ id: 'uiDashboard.lastWeekFiled' })}
                        </Typography>
                        <Typography variant="h6" align="center">
                          {dashboardData?.summary.week_end &&
                            `${dashboardData?.summary.week_start} - ${dashboardData?.summary.week_end}`}
                        </Typography>
                        <Grid container spacing={1} p={1}>
                          <Grid item xs={8} sm={6}>
                            <Typography variant="body1" align="right">
                              {intl.formatMessage({ id: 'uiDashboard.benefitsProcessed' })}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sm={6}>
                            <Typography
                              align="left"
                              variant="title"
                              sx={{ fontWeight: 500, position: 'relative', top: 3 }}
                            >
                              {dashboardData?.summary.benefits_processed_dt}
                            </Typography>
                          </Grid>
                          <Grid item xs={8} sm={6}>
                            <Typography variant="body1" align="right">
                              {intl.formatMessage({ id: 'uiDashboard.benefitsAmount' })}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sm={6}>
                            <Typography
                              align="left"
                              variant="title"
                              sx={{ fontWeight: 500, position: 'relative', top: 3 }}
                            >
                              ${dashboardData?.summary.benefits_amount}
                            </Typography>
                          </Grid>
                          <Grid item xs={8} sm={6}>
                            <Typography variant="body1" align="right">
                              {intl.formatMessage({ id: 'uiDashboard.totalDeductions' })}
                            </Typography>
                          </Grid>
                          <Grid item xs={4} sm={6}>
                            <Typography
                              align="left"
                              variant="title"
                              sx={{ fontWeight: 500, position: 'relative', top: 3 }}
                            >
                              ${dashboardData?.summary.total_deductions}
                            </Typography>
                          </Grid>
                        </Grid>
                      </CardContent>
                    </Card>
                  )}
                </Box>
              </>
            )}
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default UIDashboard;
